.flipCard {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  transform-style: preserve-3d;
  transition: 300ms;
  transform: perspective(1000px) rotateY(var(--rotate-y, 0)) translateY(var(--translate-y, 0));
}

.flipCard.flip {
  --rotate-y: 180deg;
}

.flipCard .front {
  left: 0;
  cursor: pointer;
}

.flipCard .front,
.flipCard .back {
  backface-visibility: hidden;
  width: 100%;
  height: 100%;
}

.flipCard.flip .back {
  backface-visibility: visible;
}

.flipCard.flip .front {
  visibility: hidden;
}

.flipCard .back {
  transform: rotateY(180deg);
  background-color: #eee;
}
