.ripple {
  background-position: center;
  animation: pulse 1.5s infinite;
  padding: 1rem;
}
.ripple:hover {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    background: radial-gradient(circle, rgb(126 160 195 / 1%) 1%, #00518a 1%) center/0%;
  }
  50% {
    background: radial-gradient(circle, rgb(126 160 195 / 10%) 1%, #00518a 1%) center/7500%;
  }
  100% {
    background: radial-gradient(circle, rgb(126 160 195 / 1%) 1%, #00518a 1%) center/15000%;
  }
}
